@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap");
@import "../../styles/styles.scss";

@include themify(true) {
  ///////// Mobile First Design /////////
  header {
    position: relative;
    top: 0;
    left: 0;

    height: 160px;
    width: 100vw;

    z-index: 10;

    .float-nav {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10%;

      cursor: pointer;

      &.iconM::before {
        content: "\2263";
        font-size: 50px;
        color: themed(regular-text);
        font-weight: bold;
        z-index: 200;
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;

      width: 90px;
      height: 90px;

      &:hover {
        transform: rotate(-20deg) translateY(-50%) translateX(15px);
        transition: all 1200ms ease;
      }
    }

    #menu-bar {
      display: none;
      transition: opacity ease 0.4s;
    }

    .menu {
      position: absolute;
      top: 160px;
      left: 0;
      z-index: -1;

      height: 0;
      width: 100vw;
      overflow: hidden;

      opacity: 0;
      transition: height ease-in 400ms 0ms, opacity ease-in 550ms 0ms, background-color ease-in 400ms 0ms;
      background-color: transparent;
      align-items: center;

      &.show {
        opacity: 1;
        background-color: themed(background);
        height: 375px;
        z-index: 100;
      }

      a {
        font-weight: 500;
        display: block;
        color: themed(regularText);
        height: 50px;
        text-decoration: none;
        margin-top: 0px;
        padding: 31px;
        border-bottom: 1px solid themed(regularText);
        transition: all 200ms ease;
      }

      .lang-icon {
        margin-left: 0px;
        letter-spacing: 0px;
        color: themed(regularText);

        &:hover {
          transform: scale(1.0);
        }
      }

      .header-buttons {
        display: flex;
        flex-direction: row;
        justify-content: end;
        gap: 16px;

        padding-right: 31px;
        border-bottom: 1px solid themed(regularText);

        a {
          display: flex;
          align-items: center;

          border-bottom: none;
        }

        .register-button {
          padding: 8px;
          margin: 4px 0;
          font-size: 14px;
          border-radius: 20px;
          align-content: center;
          font-weight: 500 !important;
          background-color: themed(background-purple);
          color: themed(dashboardBannerText) !important;

          &:hover {
            transform: scale(1.1);
            font-weight: bold;
            transition: all 200ms ease;
          }
        }

        .login-button {
          padding: 8px;
          font-size: 14px;
          background-color: unset;
          font-weight: 600 !important;
          color: themed(background-purple) !important;

          &:hover {
            transform: scale(1.1);
            font-weight: bold;
            transition: all 200ms ease;
          }
        }

        .lang-icon {
          height: 20px;
          width: 20px;
          margin-right: 10px;
          cursor: pointer;

          &:hover {
            transform: scale(1.2);
            transition: all 200ms ease;
          }
        }
      }
    }
  }

  ///////// Small Screens Media Query (1024 - 1549) ///////
  @media screen and (min-width: 1024px) {
    .float-nav {
      display: none;
    }

    .icon {
      position: sticky !important;
      top: unset !important;
      transform: unset !important;
      left: 20px !important;

      &:hover {
        transform: rotate(-20deg) !important;
        transition: all 1200ms ease !important;
      }
    }

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 0 120px;

      .menu {
        position: sticky !important;
        top: unset !important;
        transform: unset !important;
        left: 20px !important;
        opacity: 1;

        display: flex;
        gap: 24px;
        flex-direction: row;
        justify-content: space-between;

        height: 100%;
        width: auto;

        a {
          padding: 32px 16px;
          border-bottom: none;

          &:hover {
            transform: scale(1.1);
            color: themed(background-purple);
            transition: all 200ms ease;
          }
        }

        .header-buttons {
          padding-top: 24px;
          border-bottom: none;

          .register-button {
            padding: 0 16px !important;
            border-radius: 32px !important;

            &:hover {
              transform: scale(1.1);
              font-weight: bold;
              transition: all 200ms ease;
            }
          }
        }
      }
    }
  }

  ///////// Medium Screens Media Query (1550 - 1919) ///////
  @media screen and (min-width: 1550px) {
    header {
      padding: 0 220px;
    }
  }

  ///////// Large Screens Media Query (1920 - any) ///////
  @media screen and (min-width: 1919px) {
    header {
      padding: 0 350px;
    }
  }
}
