$color-primary: #7B5DF6;

.relative-form {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

// Estilizar los scrollbars de los navegadores: ancho de 10px, color gris, fondo blanco, y bordes redondeados
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #8C8C8C #EFEFEF;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #EFEFEF;
}

*::-webkit-scrollbar-thumb {
  background-color: #8C8C8C;
  border-radius: 20px;
  border: 3px solid #EFEFEF;
}


.upLeftCorner {
  position: absolute;
  top: -65px;
  left: 0;
  width: 24%;
  z-index: 0
}

.title {
  font-size: 40px;
  padding: 40px 0;
  color: #EA40A6;
}

.downRightCorner {
  position: absolute;
  bottom: -110px;
  right: 0;
  height: 380px;
  z-index: 0;
}

.header-spacer {
  height: 100px;
}

.stepsProgressBar {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 8px;
  background-color: $color-primary;

  &.step1 {
    width: 25%;
  }

  &.step2 {
    width: 50%;
  }

  &.step3 {
    width: 75%;
  }

  &.step4 {
    width: 100%;
  }
}