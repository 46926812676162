@import "../../../styles/styles.scss";

@include themify(true) {


  .payment-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    height: 700px;
    max-height: 719px;
  }

  .payment-area {

    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .payment-images{
      margin-top: 40px;
      height: 100%;
      width: 100%;
      padding: 0;
      display: inherit;
      justify-content: center;
      align-items: center;

      .row{
        display:flex;
        height: 100%;
        width: 100%;
      }

    }
  }
  .payment-area-container{
    margin-top: 100px;
    margin-left: 98px;
    display:flex;
    flex-flow: column;
    justify-content: flex-start;
  }

  .payment-area div {
    padding: 9px;
    justify-content: flex-start;


  }

  .container4 {
    display: flex;
    flex-direction: column;

    .child {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: flex-start;

      padding: 1px;
    }
  }

  .payment-icon{
    width: 23px;
    height: 23px;
  }

  //Images

  .image{
    position: absolute;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  .confirm{
    margin-top: 18px;
    height: 563px;
    border-radius: 24px;
  }

  .deliver{
    margin-left: -160px;
    margin-top: -100px;
    border-radius: 17px;
    height: 183px;
  }

  .payment{
    margin-left: 110px;
    margin-top: -75px;
    height: 97px;
    border-radius: 10px;
  }

  .code{
    margin-left: -110px;
    margin-top: 55px;
    height: 61px;
    border-radius: 17px;
  }
  .invoice{
    margin-left: 120px;
    margin-top: 130px;
    height: 42px;
    border-radius: 15px;
  }


.payment-cloud-blue{

  width: 100%;
  margin-top: 40px;
  z-index: -2;
}
  .title-payment{
    font-weight: bold;
    font-size: 25px;
    max-width: 350px;
  }
  .subtitle-payment{
    max-width: 469px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
  }


  /////QUERY PHONE/////
  @media screen and (max-width: 767px) {
    //BOX 1

    .payment-cloud-blue{
      position: inherit;
      width: 100vw;
      margin-top: 60px;
      z-index: -2;
    }

    .title-payment{
      font-weight: bold;
      font-size: 20px;
    }
    .subtitle-payment{
      font-size: 12px;
      max-width: 350px;


    }
    //Images

    .image{
      position: absolute;
    }

    .confirm{
      margin-top: 15px;
      height: 480px;

    }

    .deliver{
      margin-left: -65px;
      margin-top: -120px;
      height: 100px;
    }

    .payment{
      margin-left: 50px;
      margin-top: -80px;
      height: 70px;

    }

    .code{
      margin-left: -15px;
      margin-top: 70px;
      height: 50px;

    }
    .invoice{
      margin-left: 0px;
      margin-top: 170px;
      height: 34px;

    }


    .payment-area {
      padding-bottom: 20px;
    }

    .payment-area div {
      padding: 10px;
      justify-content: flex-start;
    }

    .payment-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
     max-height: max-content;
    }

    .payment-area {

      display: flex;
      flex:1;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .payment-images{
        height: 100%;
        width: 100%;
        padding: 0;
        display: inherit;
        justify-content: center;
        align-items: center;

        .row{
          display:flex;
          height: 100%;
          width: 100%;
        }

      }
    }
    .payment-area-container{
      display:flex;
      flex-flow: column;
      justify-content: flex-start;
      margin-left: 0;
    }

    .payment-area div {
      padding: 10px;
      justify-content: flex-start;


    }


    }

  /////QUERY TABLET//////
  @media (min-width: 768px) and (max-width: 1024px) {

    .title-payment{
      font-weight: bold;
      font-size: 18px;
    }
    .subtitle-payment{
      font-size: 10px;

    }

    //Images

    .image{
      position: absolute;
    }

    .confirm{
      margin-top: 15px;
      height: 420px;
    }

    .deliver{
      margin-left: -50px;
      margin-top: -80px;
      height: 110px;
    }

    .payment{
      margin-left: 50px;
      margin-top: -20px;
      height: 70px;

    }

    .code{
      margin-left: -50px;
      margin-top: 70px;
      height: 40px;

    }
    .invoice{
      margin-left: 20px;
      margin-top: 150px;
      height: 38px;

    }

    .payment-container {
      max-height: 500px;
      height: 500px;
    }
    .payment-area div {
      padding: 10px;
      justify-content: flex-start;
      max-width: 300px;}

    .payment-cloud-blue{
      width: 50vw;
      margin-top: 40px;
      z-index: -2;
    }

  }


  .deliver {
    margin-left: -300px;
    margin-top: -200px;
  }

  .payment {
    margin-top: -175px;
  }

  .code {
    margin-top: 75px;
    margin-right: 70px;
  }

  .invoice {
    margin-top: 250px;
  }

  @media screen and (min-width: 1550px) {
    .payment-area-container {
      margin-left: 170px;
    }

    .image {
      margin-right: 50px;

      &.code {
        margin-right: 150px;
      }
    }
  }

  @media screen and (min-width: 1919px) {
    .payment-area-container {
      margin-left: 250px;
    }

    .image {
      margin-right: 380px;

      &.code {
        margin-right: 540px;
      }
    }
  }
}
