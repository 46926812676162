@import "../../../styles/styles.scss";

@include themify(true) {
  .container-contact {
    max-height: 580px;
    margin-top: 80px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0 60px;
  }

  .container-hidden-scroll {
    overflow: hidden;
  }
  
  .container-prices-area {
    display: flex;
    min-width: 300px;

  }



    
    .form:first-child{
      display: flex;
    }
    
    .form {
      display: flex;
      flex-direction: column;
      height: 700px;
      width: 610px;

      .title-contact-us {
        font-size: 25px;
        font-weight: bold;
        font-family: "Montserrat", sans-serif;
        padding: 10px 0px 0px 0px;
      }

      .mybussiness-row {
        display: flex;
        flex: 1;
        padding: 5px 0px;
      }

      input:optional {
        border-color: gray;
      }
      input:required:valid {
        border-color: green;
      }
      input:invalid {
        border-color: red;
      }
      input:required:focus:valid {
        background: url("https://assets.digitalocean.com/labs/icons/hand-thumbs-up.svg") no-repeat 95% 50% lightgreen;
        background-size: 25px;
      }
      input:focus:invalid {
        background: url("https://assets.digitalocean.com/labs/icons/exclamation-triangle-fill.svg") no-repeat 95% 50% lightsalmon;
        background-size: 25px;
      }


      .mybussiness-button {
        display: flex;
        border: none;
        justify-content: flex-start;
      }

      
      & input {
        padding: 1px 10px;
      }
      
      .contact-us {
        display: flex;
        height: 81%;
        justify-content: flex-end;
        align-items: center;
        //margin-right: -20px;
        //margin-right: -400px;


      }
    }
    .reduce{
      height: 360px;
    }

  //IMG
  .imgContact{
    background: transparent;
    display: flex;
    width: 100%;
    border-radius: 20px;
  }
    
    //Utils styles
    
    .input-name {
      outline: none;
      width: 40%;
      height: 40px;
      color: themed(regularText);
      background: rgba(themed(border), 0.5);
      border: none;
      border-radius: 5px;
      font-weight: bold;

    }
    
    .input-business {
      outline: none;
      margin-left: 50px;
      width: 40%;
      height: 40px;
      color: themed(regularText);
      background: rgba(themed(border), 0.5);
      border: none;
      border-radius: 5px;
      font-weight: bold;
    }


    .input-phone {
      outline: none;
      margin-left: 50px;
      width: 40%;
      height: 40px;
      color: themed(regularText);
      background: rgba(themed(border), 0.5);
      border: none;
      border-radius: 5px;
      font-weight: bold;
    }

    .input-mail {
      outline: none;
      border: none;
      width: 40%;
      height: 40px;
      color: themed(regularText);
      background: rgba(themed(border), 0.5);

      border-radius: 5px;
      font-weight: bold;
    }

    .input-ms {
      outline: none;
      width: 88%;
      height: 100px;
      color: themed(regularText);
      background: rgba(themed(border), 0.5);
      border: none;
      border-radius: 5px;
      font-weight: bold;
    }

    .table-button {
      margin-top: 35px;
      width: 45%;
      height: 35px;
      background-color: themed(yellow_color);
      border-color: themed(yellow_color);
      border-radius: 22px;
      outline: none !important;
      border-style: none !important;
      transition: all 0.40s;
    }
  .contact-custom-button {
    height: 35px;
    background-color: themed(yellow_color);
    border-color: themed(yellow_color);
    border-radius: 22px;
    outline: none !important;
    border-style: none !important;
    transition: all 0.40s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }
  .table-button:hover, .contact-custom-button:hover {

    background-color: themed(background-purple);
    border-color: themed(background-purple);
    cursor: pointer;
    transition: all 0.40s;

  }




  /////QUERY PHONE/////
  @media screen and (max-width: 760px) {

    .container-contact {
      max-height: 100%;
    }
    //BOX 1
    .mybussiness-row{
      flex-flow: column;
      align-items: center;
      padding:0px 0px !important ;

    }

    .mybussiness-button {
      flex-flow: column;
      align-items: center;

    }
    .input-name {
      width: 70%;
      height: 50px;
      margin: 20px;
    }

    .input-business {
      height: 50px;
    width: 70%;
      margin-left: 0px;

    }


    .input-phone {
      height: 50px;
      width: 70%;
      margin-left: 0px;

    }

    .input-mail {
      height: 50px;
      width: 70%;
      margin: 20px;
    }

    .input-ms {
      margin: 20px;
      width: 70%;

    }

    .imgContact{
      align-items: center;
     position: absolute;
      left: 0px;
      width: 99%;
    }

    .form {
      display: flex;
      flex-direction: column;
      height: 600px;
      width: 500px;
      min-width: 200px;

    }
    .contact-us {
     min-width: 300px;
      margin-right: 0 !important;

    }
    .table-button {
      width: 50%;
    }



    }

  @media screen and (max-width: 767px) {
    .imgContact{
      align-items: center;
      position: absolute;
      left: 0px;
      width: 50%;
    }

  }


  @media screen and (max-width: 600px) {
    .imgContact{
      align-items: center;
      position: relative;
      left: 0;
      bottom: 0;
      width: 100%;
    }

    .contact-us {
      height: 100% !important;
    }
    .form {
      height: 500px;
    }

  }



  /////QUERY TABLET//////
  @media (min-width: 770px) and (max-width: 1024px) {



    .title-contact-us {
      font-size: 25px;
      font-weight: bold;
      font-family: "Montserrat", sans-serif;
      padding: 0px 0px 0px 45px!important;
    }

    .input-name {
      margin-left: 50px;
      outline: none;
      width: 35%;
      height: 40px;
      color: themed(regularText);
      background: rgba(themed(border), 0.5);
      border: none;
      border-radius: 5px;
      font-weight: bold;

    }

    .input-business {
      outline: none;
      margin-left: 20px;
      width: 40%;
      height: 40px;
      color: themed(regularText);
      background: rgba(themed(border), 0.5);
      border: none;
      border-radius: 5px;
      font-weight: bold;
    }


    .input-phone {
      outline: none;
      margin-left: 20px;
      width: 40%;
      height: 40px;
      color: themed(regularText);
      background: rgba(themed(border), 0.5);
      border: none;
      border-radius: 5px;
      font-weight: bold;
    }

    .input-mail {

      margin-left: 50px;
      outline: none;
      border: none;
      width: 35%;
      height: 40px;
      color: themed(regularText);
      background: rgba(themed(border), 0.5);

      border-radius: 5px;
      font-weight: bold;
    }

    .input-ms {
      margin-left: 50px;
      outline: none;
      width: 80%;
      height: 100px;
      color: themed(regularText);
      background: rgba(themed(border), 0.5);
      border: none;
      border-radius: 5px;
      font-weight: bold;
    }

    .table-button {
      margin-left: 50px;
      margin-top: 35px;
      width: 75%;
      height: 35px;
      //color: themed(background);
      background-color: themed(yellow_color);
      border-color: themed(yellow_color);
      border-radius: 22px;

      outline: none !important;
      border-style: none !important;
    }


  }





  //MODAL
  .modal-container{
    z-index: 100000000;

  }

  a {
    color: #000000;
    text-decoration: none;

  }


  .popup {
    border: 4px solid;
    position: fixed;
    padding: 30px;
    max-width: 500px;
    border-radius: 0.5em;
    top: 50%;
    left: 50%;
    color: #000;
    transform: translate(-50%, -50%);
    background-color: rgb(255, 255, 255);

    opacity: 1;
    transition: opacity .5s, visibility 0s linear .5s;
    z-index: 1;
  }
  .popup:target {
    opacity: 1;
    transition-delay: 0s;
  }
  //Icon
  .popup .close {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 5px;
    color: #000;
    transition: color .3s;
    font-size: 2em;
    line-height: 1.5;
    font-weight: 700;
  }

  .popup .close:hover {
    color: #ed2020;
  }

  .close-popup {
    background-color: rgba(0,0,0,.7);
    cursor: default;
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    opacity: 1;
    transition: opacity .5s, visibility 0s linear .5s;
  }
  .popup:target + .close-popup {
    opacity: 1;
    transition-delay: 0s;
  }

  .icon-modal{
    margin-top: -20px;
    width: 65px;
    height: 65px;
  }

  .lineOne{
    position: absolute;
    width: 150px;
    height: 170px;
    left: -20px;
    top: -15px;
    z-index: -10;
  }

  .lineTwo{
    z-index: -10;
    top: 0px;
    position: absolute;
    right: 0;
    width: 150px;
    height: 170px;
  }

  @media screen and (min-width: 1550px) {
    .container-contact {
      padding: 0 150px;
    }
  }

  @media screen and (min-width: 1919px) {
    .container-contact {
      padding: 0 250px;
    }
  }
}
